import React from 'react';
import styled from 'styled-components';

import { SimpleSplitSection, Link } from '../common';
import {
  Wrapper,
  LeftTitle,
  fontStyle,
  PlusIconList,
  PlusIconListItem,
  OrderedList,
  OrderedListItem,
} from '../common/simple-split-section';
import { ContactLinks, SiteUrls } from '../../utils/constants';

const Container = styled(Wrapper)``;

const Row = styled(SimpleSplitSection)`
  margin-top: 40px;
`;

const Paragraph = styled.p`
  ${fontStyle}
  margin-top: 0;
`;

const NestedPlusIconList = styled(PlusIconList)`
  margin-top: 15px;
`;

const ShippingContentSection: React.FC = () => {
  const data = [
    {
      title: 'I need to make changes to my order.',
      content: (
        <Paragraph>
          No problem. Give us a call or email us at{' '}
          <a href={ContactLinks.Email} target="_blank" rel="noreferrer">
            support@woosa.sg
          </a>{' '}
          and we’ll be happy to help.
        </Paragraph>
      ),
    },
    {
      title: 'When can you ship my order?',
      content: (
        <Paragraph>
          In stock items can be delivered within 3 working days.
          <br />
          <br />
          Our bed bases typically have a lead time of *8-10 weeks from the order
          date.
          <br />
          <br />
          Delivery dates for “pre-order” or “out of stock” items are subjected
          to the item’s earliest restocking date.
        </Paragraph>
      ),
    },
    {
      title: 'Are there any shipping costs?',
      content: (
        <>
          <Paragraph>
            Shipping is 100% Free for all Woosa products, provided it is lift
            accessible.
            <br />
            <br />
            For areas with no lift access, our delivery team charges $10/level
            per bulky item. Some of products are flat packed into multiple bulky
            packages- please clarify with us on the stairs charges based on your
            order for your premises.
            <br />
            <br />
            <i>
              For eg. <br />
              Adjustable Bundle:
              <br />
              Adjustable Base (2 packages) + Mattress + Headboard + Sideframe =
              <u>$50 of stairs charge / level</u>.
            </i>
            <br />
            <br />
            <i>
              For eg.
              <br />
              Storage Base Bundle:
              <br />
              Storage Base (4 packages) + Mattress= $50 of stairs charge / level
            </i>
          </Paragraph>
        </>
      ),
    },
    {
      title: ' What are your delivery timings?',
      content: (
        <>
          <Paragraph>
            Our delivery slots are from Mondays-Saturdays, 10am-12pm, 12pm-3pm,
            3pm-6pm.
          </Paragraph>
        </>
      ),
    },
    {
      title: 'Can I select my delivery date?',
      content: (
        <>
          <Paragraph>
            You are required to select your preferred or estimated delivery date
            and time for your items to be delivered, subject to the product’s
            earliest stock availability.
          </Paragraph>
        </>
      ),
    },
    {
      title: 'Can you dispose my old mattress and bed frames?',
      content: (
        <>
          <Paragraph>
            We offer mattress disposal services at an additional fee, depending
            on the size and accessibility of your mattress and base(i.e no lift
            access). Disposal services start from $30 for single/super single,
            $50 for Queen and $80 for King mattresses. Bedframe dismantling with
            disposal is chargeable at $100 across all sizes.
            <br />
            <br />
            Additional fees apply for non-lift accessible areas.
          </Paragraph>
        </>
      ),
    },
    {
      title: 'Do you offer relocation services?',
      content: (
        <>
          <Paragraph>
            We can relocate your mattresses and/or adjustable base for a fee.
            Relocation for mattress and adjustable base sets start from $120 for
            single, $140 for queen, $160 for queen and $180 for king/split king.
            Please contact us for an accurate quote for the relocation of your
            bed.
            <br />
            <br />
            Additional fees apply for non-lift accessible areas.
          </Paragraph>
        </>
      ),
    },
    {
      title: 'How long can you hold onto my order for?',
      content: (
        <>
          <Paragraph>
            We can store your mattress and bed base orders for a maximum of 2
            months from the selected delivery date. For orders exceeding the
            2-month holding period, we will charge a storage fee of $30 per
            bulky item.
            <br />
            <br />
            For example: <br />
            <br />
            You placed an order for a Woosa Mattress, Adjustable Base with
            Headboard & Side frames on the 1st of June for an estimated
            scheduled delivery on 25th September. You may choose to take
            delivery of your order anytime from 25th September to 25th November.
            <br />
            <br />
            Delivery earlier than 25th September will be subjected to your
            product’s availability, as some products may require a lead time.
            <br />
            <br />
            Should you require a delivery date later than 25th November, storage
            fees will be applied.
            <br />
            <br />
            <i>
              Storage fee breakdown for a typical adjustable base bundle:
              <br />
              Mattress: $30/month <br />
              Adustable Base: $30/month
              <br />
              Headboard Sideframes: $30/month
              <br />
              Total: $90/month in storage fees.
            </i>
            <br />
            <br />
            We can help take the stress away from getting your bed on time.
            <br />
            <br />
            Whatsapp us directly @{' '}
            <a href={ContactLinks.Whatsapp} target="_blank" rel="noreferrer">
              98485822
            </a>{' '}
            or email us at{' '}
            <a href={ContactLinks.Email} target="_blank" rel="noreferrer">
              support@woosa.sg
            </a>{' '}
            and we’ll ensure you’ll never have to sleep on the floor.
          </Paragraph>
        </>
      ),
    },
  ];

  return (
    <Container>
      {data.map(({ title, content }, i) => (
        <Row key={i} left={<LeftTitle>{title}</LeftTitle>} right={content} />
      ))}
    </Container>
  );
};

export default ShippingContentSection;
