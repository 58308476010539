import React from 'react';
import styled from 'styled-components';

import { Link, ProductAccordionSection, ProductSizeTileList } from '../common';
import { Paragraph, Subtitle } from '../common/product-accordion-section';
import { Accordion } from '../../types/component';

import { SiteUrls } from '../../utils/constants';

import AdjustableBaseSingle from '../../assets/adjustable-base-dimensions/standard/single.png';
import AdjustableBaseSuperSingle from '../../assets/adjustable-base-dimensions/standard/super-single.png';
import AdjustableBaseQueen from '../../assets/adjustable-base-dimensions/standard/queen.png';
import AdjustableBaseKing from '../../assets/adjustable-base-dimensions/standard/king.png';

import AdjustableBaseTufted from '../../assets/adjustable-base-tufted.jpeg';

const ProductSizeList = styled(ProductSizeTileList)`
  margin: 20px 0;
`;

const data: Accordion[] = [
  {
    title: 'Shipping, Return, Warranty & Cancellation',
    body: (
      <>
        <Subtitle>Shipping</Subtitle>
        <Paragraph>
          All products are shipped free to your doorstep and inclusive of
          installation. For delivery locations that are not lift accessible,
          there will be another $10 per level per item for up to a{' '}
          <b> maximum of 3 storeys / flight of stairs</b>. For more than 3
          storeys / flight of stairs – will be quoted separately.
        </Paragraph>
        <Paragraph>
          <b>Breakdown of items</b>
          <br />
          <br />
          Adjustable Base Single/Super Single – 1x
          <br />
          Adjustable Base Queen/King – 2x
          <br />
          Adjustable Base Split King – 2x
          <br />
          Headboard and Side Frames – 2x
          <br />
        </Paragraph>
        <Paragraph>
          Note: Carry-up items are required only for the above items,
          accessories do not need to be added in. Read more{' '}
          <Link to={SiteUrls.Shipping} target="_blank" rel="noreferrer">
            here
          </Link>
          .
        </Paragraph>
        <Subtitle>Return</Subtitle>
        <Paragraph>
          A restocking fee of $300 will be charged for return of one bed base
          per order, per address within the first 30 days of receiving your
          item. Read more{' '}
          <a href={SiteUrls.FreeTrial} target="_blank" rel="noreferrer">
            here
          </a>
          .
        </Paragraph>
        <Subtitle>Warranty</Subtitle>
        <Paragraph>
          The Woosa Adjustable Base II comes with a 5-year limited on-site
          warranty.{' '}
          <a href={SiteUrls.BedFrameWarranty} target="_blank" rel="noreferrer">
            Read more about our warranty
          </a>
          .
        </Paragraph>
        <Subtitle>Cancellation</Subtitle>
        <Paragraph>
          Cancellations are free within 7 days from the order date, which is
          defined as the date where either partial or full payment has been made
          for the order. Read more{' '}
          <a href={SiteUrls.FreeTrial} target="_blank" rel="noreferrer">
            here
          </a>
          .
          <br />
          <br />
          Example: If you place your order on a Saturday this week, you will
          need to cancel your order before the end of Friday next week, assuming
          there is no Public Holiday in between. Read more here.
          <br />
          <br />A cancellation fee of $300 is chargeable for orders cancelled
          after 7 days from the order date.
        </Paragraph>
      </>
    ),
  },
  {
    title: 'Frequently Asked Questions About Our Adjustable Base and Bed Frame',
    body: (
      <>
        <Subtitle>
          What are the dimensions of the Adjustable Bed Base? (excluding
          mattress)
        </Subtitle>
        <Paragraph>
          Thickness of base: 12cm (without leg)
          <br />
          3 height configurations (from the ground with leg):
          <br />
          14.5cm
          <br />
          19.5cm
          <br />
          32.5cm
          <br />
          <br />
          Single: 91cm x 191cm
          <br />
          Super Single: 107 x 191cm
          <br />
          Queen: 152cm x 191cm
          <br />
          King/Split-King: 183cm x 191cm
        </Paragraph>
        <Subtitle>
          What are the dimensions of the Adjustable Base with headboard and side
          bed frame?
        </Subtitle>
        <ProductSizeList
          items={[
            {
              name: 'Single',
              image: AdjustableBaseTufted,
              variants: [{ name: '', image: AdjustableBaseSingle }],
            },
            {
              name: 'Super Single',
              image: AdjustableBaseTufted,
              variants: [{ name: '', image: AdjustableBaseSuperSingle }],
            },
            {
              name: 'Queen',
              image: AdjustableBaseTufted,
              variants: [{ name: '', image: AdjustableBaseQueen }],
            },
            {
              name: 'King',
              image: AdjustableBaseTufted,
              variants: [{ name: '', image: AdjustableBaseKing }],
            },
          ]}
        />
        <Paragraph>
          Dimensions are the same regardless of headboard designs
          <br />
          Click on headboard size for full drawn dimensions
          <br />
          <small>
            <sup>*</sup>Buffer additional 1-2cm for manufacturing tolerances
          </small>
        </Paragraph>
        <Subtitle>
          Can I use another mattress on the Woosa Adjustable Base?
        </Subtitle>
        <Paragraph>
          The Woosa Adjustable Base is designed for intended use with Woosa
          Mattress and is also compatible with other high-quality full foam
          mattresses. We do not recommend using spring mattresses with the
          adjustable base, as it may shorten the longevity of both the mattress
          and the adjustable base and may potentially void the base&apos;s
          warranty. <Link to={SiteUrls.ComparisonMattress}>Buy a mattress</Link>{' '}
          from the extensive Woosa catalogue today.
        </Paragraph>

        <Subtitle>
          How does the Woosa Adjustable Base II compare to other adjustable bed
          bases on the market?
        </Subtitle>
        <Paragraph>
          The Woosa Adjustable Base II sets a new standard in the adjustable bed
          base market in Singapore. The state-of-the-art German Hydraulics is
          our top feature, providing a whisper-quiet operation. Every piece of
          electronics and mechanism in our adjustable bed is designed and tested
          to work seamlessly night after night, promising a reliable and smooth
          experience. Moreover, designed with versatility in mind, our Zero
          Clearance design allows the bed base to function with or without legs,
          making it a perfect fit for any bedroom aesthetic, especially platform
          bed designs. Moreover, you can tailor your Woosa Adjustable Base II to
          match your personal style with our customisable bed frames. Choose the
          headboard and side frames that resonate with your decor, truly making
          your bed a part of your home. The DUALPOINT Back-Tilt for extra lumbar
          support and a quad rhythmic massage function, also sets our base
          apart. All these features, combined with a user-friendly interface and
          a companion app, make the Woosa Adjustable Base II a superior choice
          for sleepers seeking a customisable and comfortable sleep experience.
        </Paragraph>

        <Subtitle>
          What certifications does the Woosa Adjustable Base II have to ensure
          its quality and safety?
        </Subtitle>
        <Paragraph>
          The Woosa Adjustable Base II holds certifications confirming its
          superior safety standards and quality. It&apos;s tested and certified
          under stringent conditions to ensure it provides a safe, reliable, and
          comfortable experience for users. Our base has also proven its
          resilience and durability through rigorous internal testing. As a
          matter of fact, each unit is rated to withstand in excess of 10,000
          repeated movements, testifying to its exceptional reliability and
          long-term performance. Further details on specific certifications can
          be obtained from our customer service team. Alternatively, you can
          head down to our{' '}
          <Link to={SiteUrls.Home}>mattress showroom in Singapore</Link> to get
          a first-hand experience of our innovative adjustable and automatic bed
          frame.
        </Paragraph>

        <Subtitle>
          How does the Woosa Adjustable Base II address specific sleep concerns?
        </Subtitle>
        <Paragraph>
          The Adjustable Base II is designed with a host of features to cater to
          various sleep concerns. For instance, the DUALPOINT Back-Tilt can
          provide extra lumbar support for those with back pain. Additionally,
          our vibrating adjustable bed feature, the quad rhythmic massage, could
          possibly help individuals with difficulty getting a good night&apos;s
          rest to fall asleep quicker. Further enhancing the offering of sleep
          solutions, our base offers an &apos;Anti-snore&apos; position. By
          subtly elevating the head, this configuration opens up airways,
          promoting better airflow and significantly reducing the likelihood of
          snoring.
        </Paragraph>

        <Subtitle>
          Can the Woosa Adjustable Base II accommodate split mattresses for
          individual customisation of sleep positions?
        </Subtitle>
        <Paragraph>
          Absolutely, the Woosa Adjustable Base II even works perfectly with our
          split king mattress design, allowing each sleeper to individually
          control their side of the bed for optimal comfort and independent
          adjustment. Choose from various base thickness and decide on the
          optimal bed frame size to cater to your sleeping needs.
        </Paragraph>

        <Subtitle>
          Are there any safety precautions or considerations to keep in mind
          when using the massage feature of the Woosa Adjustable Base II?
        </Subtitle>
        <Paragraph>
          When enjoying the rhythmic massage function of the Woosa Adjustable
          Base II, it&apos;s vital to ensure your safety and the longevity of
          the product. Please refrain from opening any control boxes, motors or
          remote control devices, excluding the remote control and power-down
          box battery compartments. This is because your product warranty could
          be voided if these parts are tampered with.
          <br />
          <br />
          To maintain the product&apos;s integrity, please avoid altering
          component wiring or adjusting the product&apos;s structure in any way.
          All repairs or replacements of base parts should be performed by
          authorised personnel only. And as much as we understand the joy and
          relaxation our base brings, it&apos;s important to treat the base
          gently. Do not jump on the base or exert excessive force. Stay within
          the recommended weight limit and usage rating to keep your Adjustable
          Base II functioning optimally and safely.
        </Paragraph>

        <a href={SiteUrls.Faq} target="_blank" rel="noreferrer">
          Read All FAQs
        </a>
      </>
    ),
  },
];

const AdjustableBaseAccordionSection: React.FC = () => {
  return <ProductAccordionSection data={data} initCollapseAll />;
};

export default AdjustableBaseAccordionSection;
